import { types, initState } from "./types";

const getKpi = (state = initState, action) => {
  switch (action.type) {
    case types.REFRESH_KPIS:
      /* eslint-disable */
      return {
        ...state,
        historicKpis: null,
        monthRef: null,
        isLoading:
          action.payload && action.payload.isLoading === false ? false : true,
      };
    case types.GET_KPI_SCORE_SUCCESS:
      return {
        ...state,
        groupKpis: action.groupKpis,
        draftKpis: action.draftKpis,
        companyKpis: action.companyKpis,
        usersKpis: action.usersKpis,
        myKpis: action.myKpis,
        adminKpis: action.adminKpis,
        isLoading: false,
        monthRef: null, // Reset to Present month
        error: null,
      };
    case types.GET_KPI_SCORE_EMPTY:
      return {
        ...state,
        groupKpis: [],
        companyKpis: [],
        usersKpis: [],
        myKpis: null,
        adminKpis: null,
        isLoading: false,
        monthRef: null, // Reset to Present month
        error: "No KPIs",
      };
    case types.GET_KPI_SCORE_FAILED:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case types.GET_PREVIOUS_KPIS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_PREVIOUS_KPIS_SUCCESS:
      return {
        ...state,
        historicKpis: { ...state.historicKpis, ...action.historicKpis },
        monthRef: action.monthRef,
        isLoading: false,
      };
    case types.GET_PREVIOUS_KPIS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case types.SET_TO_LIVE_KPIS:
      // Removes monthRef to disable historic data
      return {
        ...state,
        monthRef: null,
      };
    case types.SET_TO_HISTORIC_KPIS:
      return {
        ...state,
        isLoading: false,
        monthRef: action.monthRef,
      };
    case types.ADD_USER_KPI_REQUEST:
    case types.UPDATE_USER_KPI_REQUEST:
    case types.DELETE_USER_KPI_REQUEST:
      return {
        ...state,
        error: null,
      };
    case types.ADD_OLD_USER_KPI_SUCCESS:
    case types.ADD_USER_KPI_SUCCESS:
      return {
        ...state,
        usersKpis: action.usersKpis,
        myKpis: action.myKpis,
      };
    case types.ADD_USER_KPI_FAILED:
    case types.ADD_OLD_USER_KPI_FAILED:
    case types.UPDATE_USER_KPI_FAILED:
    case types.DELETE_USER_KPI_FAILED:
      return {
        ...state,
        error: action.message || action,
      };
    default:
      return state;
  }
};

export default getKpi;