/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";
import { KPI_IMAGE_PARAMS } from "../../../constants/kpi";
import { FETCH_JOB } from "../../../api/jobs";
import { GET_IMAGE } from "../../../helpers/images";
import { UseIsAdmin } from "../../../hooks/auth";
import { formatNumber } from "../../../helpers/formatter";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import {
  ClickAwayListener,
  Divider,
  useTheme,
  Backdrop,
  Dialog,
} from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "../../SoftBox";
import SoftTypography from "../../SoftTypography";
import SoftInput from "../../SoftInput";
import SoftButton from "../../SoftButton";
import FeedItem from "../../../pages/Feed/FeedItem";
import ConfirmatonModal from "../../Modals/ConfirmationModal";
import InputModal from "../../Modals/InputModal";

// Timeline context
import { useTimeline } from "../context";

// Custom styles for the TimelineItem
import { timelineItem, timelineItemIcon } from "./styles";

// Icon
import { ReactComponent as MessageIcon } from "../../../img/timeline-messageicon.svg";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { Edit as EditIcon, DeleteOutline } from "@mui/icons-material";

function TimelineItem({
  color,
  icon,
  title,
  date,
  description,
  lastItem,
  time,
  eKpidId,
  notesText,
  timeline,
  handleOpenNotes,
  handleKpiUpdate,
  handleKpiDelete,
}) {
  const isDark = useTimeline();
  const theme = useTheme();
  const {
    primary: { main: primaryColor },
  } = theme.palette;
  const isAdmin = UseIsAdmin();
  const [openNotes, setOpenNotes] = useState(false);
  const [messages, setMessages] = useState(null);
  const [referenceItem, setReferenceItem] = useState(null);
  const [viewDetails, setViewDetails] = useState(null);

  const handleOpen = () => {
    setOpenNotes(true);
    handleOpenNotes?.(eKpidId);
  };

  const handleClose = () => {
    setOpenNotes(false);
  };

  const formattedAmt = formatNumber(timeline.amount, timeline.kind);

  const showItem = ({ jobId, imageKey, notes }) => {
    if (jobId) {
      FETCH_JOB(jobId).then(({ data }) => {
        setReferenceItem({ job: data });
      });
    } else if (imageKey) {
      setReferenceItem({ imageKey, notes });
    }
  };

  const jobId =
    // eslint-disable-next-line no-nested-ternary
    timeline.refModel === "Job"
      ? timeline.refId
      : timeline.claimModel === "Job"
      ? timeline.claimId
      : null;

  let refItem = null;
  if (jobId) {
    // Show Job Feed item
    refItem = (
      <SoftButton
        variant="text"
        size="small"
        color="primary"
        onClick={() => showItem({ jobId })}
        sx={{ paddingX: 0 }}
      >
        View Job{" "}
        <ArrowForwardIosRoundedIcon
          sx={{ marginLeft: "0.25rem", color: "primary.main" }}
        />
      </SoftButton>
    );
  } else {
    // Find if an image item exists
    const imageItem =
      timeline.metadata &&
      Object.keys(timeline.metadata).find((item) =>
        KPI_IMAGE_PARAMS.some((key) => item.includes(key)),
      );
    // Compile input parameters, but skip the image item
    const notes =
      timeline.metadata &&
      Object.values(timeline.metadata)
        .filter((item) => item !== timeline.metadata[imageItem] && typeof item !== 'object')
        .join("\n");

    if (imageItem) {
      refItem = (
        <SoftButton
          variant="text"
          size="small"
          color="primary"
          onClick={() =>
            showItem({ imageKey: timeline.metadata[imageItem], notes })
          }
          sx={{ paddingX: 0 }}
        >
          View Image{" "}
          <ArrowForwardIosRoundedIcon
            sx={{ marginLeft: "0.25rem", color: "primary.main" }}
          />
        </SoftButton>
      );
    } else {
      refItem = (
        <>
          <SoftButton
            variant="text"
            size="small"
            color="primary"
            sx={{ paddingX: 0 }}
            onClick={() => setViewDetails(true)}
          >
            View Details
            <ArrowForwardIosRoundedIcon
              sx={{ marginLeft: "0.25rem", color: "primary.main" }}
            />
            {/* {notes ?? "None"} */}
          </SoftButton>
          <Dialog
            open={viewDetails}
            onClose={() => setViewDetails(false)}
            sx={{
              ".MuiPaper-root": {
                borderRadius: "10px",
                width: "100%",
                background:
                  "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
                boxShadow:
                  "-15.85294px -15.85294px 26.42157px 0px rgba(0, 0, 0, 0.20) inset, 15.85294px 15.85294px 31.70588px 0px rgba(0, 0, 0, 0.20)",
                backdropFilter: "blur(20px)",
                padding: { mini: "1rem", md: "1.5rem 1.5rem" },
              },
              ".urlLink": {
                "& a": { color: "#fff", textDecoration: "underline" },
                "& a:hover": { color: "#fff", opacity: "0.7" }
              }
            }}
          >
            <SoftBox>
              <SoftTypography
                variant="button"
                sx={{ color: "#CED4DA", fontWeight: 400 }}
              >
                
                {((notes ?? "None").includes('http'))
                  ? <div className="urlLink">
                      <a href={notes} target="blank">{notes}</a>
                    </div>
                  : <div>
                      {notes ?? "None"}
                    </div>
                }
              </SoftTypography>
            </SoftBox>
          </Dialog>
        </>
      );
    }
  }

  return (
    <SoftBox display="flex" justifyContent="space-between">
      <SoftBox display="grid" sx={{ gridTemplateColumns: "max-content 1fr" }}>
        <SoftBox display="flex" flexDirection="column" width="60px">
          <SoftTypography
            variant="button"
            fontWeight="medium"
            sx={{ color: "rgba(52, 58, 64, 1)" }}
          >
            {date}
          </SoftTypography>
          <SoftTypography
            variant="caption"
            sx={{ color: "rgba(52, 58, 64, 1)", fontWeight: 400 }}
          >
            {time}
          </SoftTypography>
        </SoftBox>
        <SoftBox
          position="relative"
          sx={() => timelineItem(theme, { lastItem })}
        >
          <SoftBox
            bgColor="transparent"
            width="1.625rem"
            height="1.625rem"
            borderRadius="50%"
            position="absolute"
            top="3.25%"
            left="2px"
            zIndex={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {icon}
          </SoftBox>
          <SoftBox ml={5.75} lineHeight={0} maxWidth="30rem">
            <SoftTypography variant="h6" fontWeight="medium" color="black">
              {title}
            </SoftTypography>
            {/* <SoftBox mt={0.5}>
              <SoftTypography
                variant="caption"
                fontWeight="medium"
                color={isDark ? "secondary" : "text"}
              >
                {dateTime}
              </SoftTypography>
              </SoftBox> */}
            <SoftBox mt={0.5} mb={1.5}>
              {description ? (
                <SoftTypography
                  variant="button"
                  fontWeight="regular"
                  sx={{ color: "rgba(108, 117, 125, 1)" }}
                >
                  {description}
                </SoftTypography>
              ) : null}
            </SoftBox>
            {isAdmin && (
              <SoftBox mt={0.5} mb={1.5}>
                <SoftBox className="flex flex-row gap-1">
                  <InputModal
                    title={`Update ${timeline.kpiName} Score`}
                    question={`Do you want to update ${timeline.name}'s  ${timeline.kpiName} KPI record? Current amount: ${formattedAmt}`}
                    yesText="Yes, update"
                    noText="No, cancel"
                    label="New Value"
                    isNumericInput={true}
                    onsuccess={(value) =>
                      handleKpiUpdate(timeline._id, timeline.kpiId, value)
                    }
                  >
                    <SoftButton
                      variant="outlined"
                      iconOnly={true}
                      color="primary"
                      sx={{
                        fontSize: "1rem",
                        width: "max-content",
                        height: "max-content",
                        minWidth: "max-content",
                        minHeight: "max-content",
                        padding: "0.25rem",
                      }}
                    >
                      <EditIcon />
                    </SoftButton>
                  </InputModal>
                  <ConfirmatonModal
                    title={`Delete ${timeline.kpiName} Score`}
                    question={`Are you sure you want to delete \n ${timeline.name}'s ${formattedAmt} ${timeline.kpiName} KPI record?`}
                    yesText="Yes, delete"
                    noText="No, cancel"
                    onSuccess={() => handleKpiDelete(timeline._id)}
                  >
                    <SoftButton
                      variant="outlined"
                      iconOnly={true}
                      color="primary"
                      sx={{
                        fontSize: "1rem",
                        width: "max-content",
                        height: "max-content",
                        minWidth: "max-content",
                        minHeight: "max-content",
                        padding: "0.25rem",
                      }}
                    >
                      <DeleteOutline />
                    </SoftButton>
                  </ConfirmatonModal>
                </SoftBox>
              </SoftBox>
            )}
            <SoftBox mt={0.5} mb={1.5}>
              {refItem}
            </SoftBox>
            {referenceItem && (
              <Backdrop
                sx={{ zIndex: () => theme.zIndex.drawer + 1 }}
                open={referenceItem !== null}
                onClick={() => setReferenceItem(null)}
              >
                <SoftBox
                  sx={{
                    width: { xs: "90vw", sm: "80vw", xl: "70vw" },
                    maxHeight: { xs: "95vh", lg: "85vh" },
                  }}
                  className="flex justify-center"
                >
                  {referenceItem.job && (
                    <FeedItem job={referenceItem.job} isModal />
                  )}
                  {referenceItem.imageKey && (
                    <SoftBox
                      borderRadius="lg"
                      shadow="lg"
                      bgColor="white"
                      className="flex flex-col w-[90%] p-8 gap-4 items-center"
                    >
                      <SoftBox borderRadius="lg" className="overflow-hidden">
                        <img
                          src={GET_IMAGE(referenceItem.imageKey)}
                          height="100%"
                          alt="Reference Item"
                          style={{
                            objectFit: "cover",
                            objectPosition: "center",
                          }}
                        />
                      </SoftBox>
                      <SoftTypography variant="body2">
                        {referenceItem.notes}
                      </SoftTypography>
                    </SoftBox>
                  )}
                </SoftBox>
              </Backdrop>
            )}

            <SoftBox display="flex" alignItems="center" pb={lastItem ? 1 : 2}>
              <SoftBox
                width="max-content"
                display="flex"
                alignItems="center"
                gap="0.25rem"
                sx={{ cursor: "pointer" }}
                onClick={handleOpen}
              >
                <MessageIcon />
                <SoftTypography
                  variant="caption"
                  fontWeight="regular"
                  sx={{ color: "rgba(108, 117, 125, 1)" }}
                >
                  {notesText}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

// Setting default values for the props of TimelineItem
TimelineItem.defaultProps = {
  color: "info",
  lastItem: false,
  description: "",
};

// Typechecking props for the TimelineItem
TimelineItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  description: PropTypes.string,
  lastItem: PropTypes.bool,
};

export default TimelineItem;
