import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { refreshKpis } from "../redux/kpi/actions";
import { UseAuth, UseUserDetails, UseIsDemo } from "./auth";
import {
  UseSelectedWorkspace,
  UseUserIdList,
  UseUsersDictionary,
  UseYetiStats,
} from "./company";
import yetiProfile from "../constants/yetiProfile";

export const UseKpiRefresh = () => {
  const authenticated = UseAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (authenticated) {
      dispatch(refreshKpis());
    }
  }, [dispatch, authenticated]);
};

export const UseKpiLoading = () => {
  const loading = useSelector((state) => state.kpiReducer.isLoading);
  return loading;
};

export const UseGroupKpis = () => {
  const groupKpis = useSelector((state) => state.kpiReducer.groupKpis);
  return groupKpis;
};

export const UseCompanyKpis = () => {
  const companyKpis = useSelector((state) => state.kpiReducer.companyKpis);
  return companyKpis;
};

/**
 * Returns user scores, returns workspace average instead if admin
 * @param {bool} forceAdmin if true, returns workspace average scores whether current user is admin or not
 */
export const UseMyKpis = (forceAdmin = false) => {
  const myKpis = useSelector((state) => state.kpiReducer.myKpis);
  const adminKpis = useSelector((state) => state.kpiReducer.adminKpis);
  const topKpis = useSelector((state) => state.kpiReducer.usersKpis?.[0]);
  const [kpis, setKpis] = useState(myKpis);
  const isDemo = UseIsDemo();
  // Refer to true isAdmin value, not on the UseIsAdmin which is based on player/admin view
  const { isAdmin } = UseUserDetails();

  useEffect(() => {
    if (isDemo) {
      setKpis(topKpis);
    } else if (isAdmin || forceAdmin) {
      setKpis(adminKpis);
    } else {
      setKpis(myKpis);
    }
  }, [myKpis, adminKpis, topKpis, isDemo, isAdmin]);

  return kpis;
};

/**
 *
 * @param {string} period accepts yesterday, today, week, month
 * @param {*} month date reference
 * @returns array of player score objects in descending score order
 */
export const UseTopPlayers = (period = "month", month = null) => {
  const [stats, setStats] = useState(null);
  const monthRef = month?.format("YYYY-MM");
  const usersKpis = useSelector((state) =>
    monthRef
      ? state.kpiReducer.historicKpis?.[monthRef]
      : state.kpiReducer.usersKpis,
  );
  const userId = useSelector((state) => state.authReducer.userDetails?._id);
  const usersDict = UseUsersDictionary();
  const yeti = UseYetiStats(!monthRef);

  useEffect(() => {
    if (usersDict && usersKpis && yeti) {
      const topPlayers = usersKpis.map((item) => ({
        userId: item.userId,
        scores: item[period],
        today: item.today?.total,
        yesterday: item.yesterday?.total,
        yesterdayRank: item.yesterdayRank,
        ...usersDict[item.userId],
        ...(item.userId === userId && { ownId: true }),
      }));

      topPlayers.push({
        ...yetiProfile,
        scores: yeti[period],
        today: yeti.today.total,
        yesterday: yeti.today.total,
        isYeti: true,
      });

      if (!monthRef) {
        // Previous month's scores; skip yesterday comparison
        // Order based on yesterday's scores and record ranking
        if (period === "today") {
          // Compare points yesterday versus today
          topPlayers.sort((a, b) => b.yesterday - a.yesterday);
        } else {
          // Compare points yesterday versus today, but relative to the month or week's standing
          topPlayers.sort(
            (a, b) => b.scores?.total - b.today - (a.scores?.total - a.today),
          );
        }
        topPlayers.forEach((user, index) => {
          user.yesterdayRank = index + 1;
        });
      }
      topPlayers.sort((a, b) => b.scores?.total - a.scores?.total);
      setStats(topPlayers);
    }
  }, [period, yeti, usersKpis, usersDict, monthRef]);

  return stats;
};

// table of user KPI scores
export const UseKpiScoresTable = (month = null) => {
  const groupKpis = useSelector((state) => state.kpiReducer.groupKpis);
  const monthRef = month?.format("YYYY-MM");
  const usersKpis = useSelector((state) =>
    monthRef
      ? state.kpiReducer.historicKpis?.[monthRef]
      : state.kpiReducer.usersKpis,
  );
  const usersDict = UseUsersDictionary();
  const usersList = UseUserIdList();
  const currentWorkspace = UseSelectedWorkspace();
  const [kpiTable, setKpiTable] = useState([]);

  useEffect(() => {
    // Add top scorers on top of table
    const newKpiTable = [];
    const allUsers = [...usersList];

    if (usersKpis?.length) {
      for (let u = 0; u < usersKpis.length; u++) {
        const user = usersKpis[u];
        const { groupId, isArchived } = usersDict[user.userId] ?? {};

        // Filter user by selected workspace, and not archived
        if (isArchived || groupId !== currentWorkspace._id) continue;

        const kpiScores = [];
        for (let k = 0; k < groupKpis.length; k++) {
          if (user.month[groupKpis[k]._id]) {
            kpiScores.push(user.month[groupKpis[k]._id].amt);
          } else {
            kpiScores.push(0);
          }
        }
        allUsers.splice(allUsers.indexOf(user.userId), 1);
        newKpiTable.push({
          userId: user.userId,
          info: usersDict[user.userId],
          kpis: kpiScores,
          total: user.month.total,
        });
      }
    }

    // Add zero-scorers next
    allUsers.map((user) => {
      const { groupId, isArchived } = usersDict[user] ?? {};

      // Filter user by selected workspace, and not archived
      if (isArchived || groupId !== currentWorkspace._id) return;

      newKpiTable.push({
        userId: user,
        info: usersDict[user],
        kpis: null,
        total: 0,
      });
    });
    setKpiTable(newKpiTable);
  }, [currentWorkspace, usersKpis, groupKpis, usersList, usersDict]);

  return kpiTable;
};

export const UseDraftKpis = () => {
  const draftKpis = useSelector((state) => state.kpiReducer.draftKpis);
  return draftKpis;
};