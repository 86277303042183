import React, { useEffect, useState, useMemo } from "react";
import useIntegrationsData from "./useIntegrationsData";

import { UseGroupKpis } from "../../../../hooks/kpi";

/**
 * Fetches, filters and builds Integrations data, borrowed from /WorkspaceKpi.jsx
 * @param {Bool} disableActive - Flag to disable active Integrated metrics. Defaults to false.
 * @returns {Object} { connectedApps, integrations, isLoading, error }
 *  - connectedApps {Object[]}: list of connected apps (integrated accounts)
 *  - activeMetricsSimpleMap: {Object} list of activated Integrated and GMB Data Cube metrics
 *  - isLoading {Bool}: Flag if data is currently being fetched
 *  - error {String}: Fetching or parsing error messages
 */
const useIntegratedMetricsData = (disableActive = false) => {
  const [filteredApps, setFilteredApps] = useState([]);
  const groupKpis = UseGroupKpis();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { connectedApps, integrations, isLoading } = useIntegrationsData();

  // Detect activated metrics key existence in an Object instead of searching in an Array
  // (array loop optimization for very long metrics list)
  const activeMetricsSimpleMap = useMemo(() => {
    return groupKpis?.reduce((obj, item) => {
      const metricComboKey = `${item?.source ?? item?.kpiModelId ?? ""}|${item?.sourceId ?? ""}`;
      return { ...obj, [metricComboKey]: item._id }
    }, {});
  }, [groupKpis]);

  useEffect(() => {
    setLoading(true);
    if (connectedApps && connectedApps.length && integrations) {
      const allAppIntegrations = [];
      const appsList = [...connectedApps].map((app) => {
        const officialIntegrations = app.dataSets
          ? app.dataSets.flatMap(
              (d) =>
                (integrations[d] ?? []).map(
                  ({ sourceId, type, description }) => ({
                    type,
                    description,
                    app: app.name,
                    logo: app.logo,
                    code: app.code,
                    sourceId,
                    integrationId: app._id,
                    isConnected: app.isConnected,
                  }),
                ) ?? [],
            )
          : [];

        // Merge GMB data cubes (template manual KPIs) and Zapier zaps with official integrations
        const allIntegrations = [
          ...officialIntegrations,
          ...(app.models?.map((m) => ({
            ...m,
            app: app.name,
            logo: app.logo,
            code: app.code,
            integrationId: null,
            isManual: true,
            isConnected: true,
          })) ?? []),
          ...(app.zaps?.map((z) => ({
            ...z,
            app: app.name,
            logo: app.logo,
            code: app.code,
            integrationId: app._id,
            isConnected: app.isConnected,
          })) ?? []),
        ];

        allAppIntegrations.push(...allIntegrations);
        return { ...app, metrics: allIntegrations };
      });

      const appMetricsData = [];
      appsList.forEach((item, index) => {
        // Always add manual entry for "No Data Source"
        appMetricsData.push({
          sourceId: "62f0d62b17ceab3cbca3957c",
          type: null,
          kpiModelId: "66bf3613f9b35332d5b5bd50",
          value: "62f0d62b17ceab3cbca3957c",
          label: "No Data Source",
          isDisabled: false,
        });
      
        item.metrics.forEach((metric) => {
          const integrationMeta = metric.isZapier
            ? {
                id: metric.id,
                code: metric.code,
                status: "disconnected",
              }
            : null;
      
          const code = metric?.app === "Game My Biz" ? metric.kpiModelId : metric?.code;
      
          const data = {
            sourceId: metric.sourceId,
            type: metric.type,
            kpiModelId: metric.kpiModelId,
            ...(metric.integrationId && {
              integrationId: metric.integrationId,
            }),
            ...(integrationMeta && { integrationMeta }),
            value: `${metric.sourceId ?? index}-${metric.app}`,
            label: `${metric.type} (${metric.app})`,
            description: metric.description,
            isDisabled: disableActive
              ? activeMetricsSimpleMap[`${code ?? ""}|${metric?.sourceId ?? ""}`]
              : false,
          };
      
          appMetricsData.push(data);
        });
      });
      
      setFilteredApps(appMetricsData);
    }
    setLoading(false);
  }, [connectedApps, integrations, isLoading]);

  return {
    filteredApps,
    activeMetricsSimpleMap,
    isLoading,
    error,
  };
};

export default useIntegratedMetricsData;
