/* eslint camelcase: 0 */

import React, { useEffect, } from "react";

import { POST_EMBED_TOKEN } from "../../../api/integrations/zapier";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const Authentication = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    const params = Object.fromEntries(new URLSearchParams(window.location.hash.split("#")[1]));
    const {access_token, token_type} = params;
    if(access_token && token_type) {
      POST_EMBED_TOKEN({access_token, token_type})
      .then(() => {
        toast.success("Zapier access credentials saved.")
        navigate("/gameboards/workspace-kpi/custom-metric-types");
      })
    } else {
      toast.error("Zapier access credentials not found.");
    }
  }, [navigate]);

  return < > </>;
};

export default Authentication;
