import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery, Popover, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useDispatch } from "react-redux";
import SoftBox from "../SoftBox";
import SoftButton from "../SoftButton";
import SoftTypography from "../SoftTypography";
import Tooltip from "@mui/material/Tooltip";
import EditKpi from "./metrics/EditKpi";
import { formatNumber } from "../../helpers/formatter";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import Diversity3Icon from "@mui/icons-material/Diversity3";
import MoreHorizIcon from "@mui/icons-material/MoreVert";
import zapierLogo from "../../img/brands/zapier-logo_black.png";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import FlashOffIcon from "@mui/icons-material/FlashOff";
import { KPI_TYPES } from "../../constants/kpi";
import EditDisplayMetric from "./metrics/EditDisplayMetric";
import SoftDialog from "../SoftConfirmationDialog";
import { disableWorkspaceKpi } from "../../redux/kpi/actions";
import SoftProfile from "../ProfilePictures/SoftProfile";
import { GMB_LOGO_URL } from "../../constants/branding";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SoftInput from "../SoftInput";

import popNotification from "../Modals/popNotification";

const Kpi = ({ kpi, target, allApps }) => {
  const dispatch = useDispatch();

  const [openPopUp, setOpenPopUp] = useState(false);
  const [openConfirmationPopUp, setOpenConfirmationPopUp] = useState(false);
  const isZapier = useMemo(() => kpi.integrationMeta, [kpi.integrationMeta]);
  const isZapDisconnected = useMemo(
    () => kpi.integrationMeta && kpi.integrationMeta.status === "disconnected",
    [kpi.integrationMeta],
  );
  const navigate = useNavigate();

  const theme = useTheme();
  const pointsBp = useMediaQuery(() =>
    theme.breakpoints.between("xxl", "xxxl"),
  );

  const [confirmText, setConfirmText] = useState("");

  const isProceedEnabled = useMemo(() => {
    if (kpi.title === "Yard Sign") {
      return confirmText.toLowerCase() === "confirm";
    }
    return true;
  }, [kpi.title, confirmText]);

  // const handleOpen = () => setOpenPopUp(true);
  // const handleClose = () => setOpenPopUp(false);
  const [openMenu, setOpenMenu] = useState(null);
  const handleOpen = (event) => {
    setOpenMenu(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = () => {
    setOpenMenu(null);
  };

  const triggerDelete = () => {
    dispatch(disableWorkspaceKpi(kpi._id));
    handleClose();
  };

  const handleConfirmationOpen = () => {
    handleClose();
    setOpenConfirmationPopUp(true);
  };
  const handleConfirmationClose = () => setOpenConfirmationPopUp(false);

  const metricType = useMemo(() => {
    let kpiMetricType = kpi?.metricType;

    // KPI must be an older Custom Created Metric (no "metricType" yet) - mostly "Progressive" in nature
    // 20240410: It could also be an Integrated metric, which are "Progressive" type of metrics
    if (!kpiMetricType) {
      kpiMetricType =
        kpi.target === 0 && kpi.pointScale === 0
          ? KPI_TYPES[2] // Display metric
          : KPI_TYPES[0]; // Progressive metric
    }

    return kpiMetricType;
  }, [kpi]);

  const getLogo = (source) => {
    const matchingApp = allApps.find(appItem => appItem.code === source);
    return matchingApp ? matchingApp.logo : GMB_LOGO_URL;
  };

  const handleDelete = () => {
    handleConfirmationClose();
    dispatch(disableWorkspaceKpi(kpi._id));
    // Fake success notification that appears after a short delay (and not when data has been successfully processed)
    setTimeout(
      () =>
        popNotification({
          title: "Metric Deleted",
          text: `You've successfully deactivated ${kpi.title.toUpperCase()} metric.`,
        }),
      1500,
    );
    handleClose();
  };

  return (
    <>
      <SoftBox
        bgColor="white"
        sx={{
          height: "100%",
          boxShadow: "box-shadow: 0px 2px 3px 0px rgba(149, 144, 144, 0.04)",
          background: "#FCFCFD",
          border: "1px solid #F2F4F7",
          borderRadius: '8px'
        }}
      >
        <SoftBox p={2}>
          <SoftBox sx={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #EAECF0", paddingBottom: "1rem"}}>
            <SoftBox>
              <SoftTypography
                variant={pointsBp ? "h2" : "h1"}
                fontWeight="regular"
                sx={{ color: "#667085", fontSize: "16px", fontWeight: 600 }}
              >
                Daily Target <InfoOutlinedIcon className="text-[#98A2B3]" />
              </SoftTypography>
            </SoftBox>
            <SoftBox sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}>
              <style>
                {`
                  @keyframes pop {
                    0% {
                      transform: scale(1);
                    }
                    50% {
                      transform: scale(1.2);
                    }
                    100% {
                      transform: scale(1);
                    }
                  }

                  .pop-animation {
                    animation: pop 1.5s ease-in-out infinite;
                  }
                `}
              </style>
              {!kpi.isDraft && (
                <>
                  <SoftTypography
                    variant={pointsBp ? "h2" : "h1"}
                    fontWeight="regular"
                    sx={{ color: "#047E11", fontSize: "16px", fontWeight: 600 }}
                  >
                    LIVE
                  </SoftTypography> &nbsp;&nbsp;
                </>
              )}
              {kpi.isDraft ? (
                <svg className="pop-animation" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.5" filter="url(#filter0_ii_11022_868)">
                    <circle cx="10" cy="10" r="10" fill="#E1CF07" fillOpacity="0.2"/>
                  </g>
                  <g filter="url(#filter1_ii_11022_868)">
                    <circle cx="10" cy="10" r="7" fill="url(#paint0_radial_11022_868)"/>
                  </g>
                  <defs>
                    <filter id="filter0_ii_11022_868" x="0" y="0" width="25.7143" height="25.7143" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                      <feOffset dx="5.71428" dy="5.71428"/>
                      <feGaussianBlur stdDeviation="2.85714"/>
                      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0.0771528 0 0 0 0 0.420833 0 0 0 0 0.111521 0 0 0 0.25 0"/>
                      <feBlend mode="normal" in2="shape" result="effect1_innerShadow_11022_868"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                      <feOffset dx="5.71428" dy="5.71428"/>
                      <feGaussianBlur stdDeviation="2.85714"/>
                      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0.00729167 0 0 0 0 0.116667 0 0 0 0 0.0182292 0 0 0 0.25 0"/>
                      <feBlend mode="normal" in2="effect1_innerShadow_11022_868" result="effect2_innerShadow_11022_868"/>
                    </filter>
                    <filter id="filter1_ii_11022_868" x="3" y="3" width="18" height="18" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                      <feOffset dx="4" dy="4"/>
                      <feGaussianBlur stdDeviation="2"/>
                      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0.0771528 0 0 0 0 0.420833 0 0 0 0 0.111521 0 0 0 0.25 0"/>
                      <feBlend mode="normal" in2="shape" result="effect1_innerShadow_11022_868"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                      <feOffset dx="4" dy="4"/>
                      <feGaussianBlur stdDeviation="2"/>
                      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0.00729167 0 0 0 0 0.116667 0 0 0 0 0.0182292 0 0 0 0.25 0"/>
                      <feBlend mode="normal" in2="effect1_innerShadow_11022_868" result="effect2_innerShadow_11022_868"/>
                    </filter>
                    <radialGradient id="paint0_radial_11022_868" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10 10) rotate(90) scale(5.5)">
                      <stop stopColor="#E1CF07"/>
                      <stop offset="1" stopColor="#E0DEA3"/>
                    </radialGradient>
                  </defs>
                </svg>
              ) : (
                <svg className="pop-animation" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.5" filter="url(#filter0_ii_6112_783)">
                    <circle cx="10" cy="10" r="10" fill="#07E11C" fillOpacity="0.2"/>
                  </g>
                  <g filter="url(#filter1_ii_6112_783)">
                    <circle cx="10" cy="10" r="7" fill="url(#paint0_radial_6112_783)"/>
                  </g>
                  <defs>
                    <filter id="filter0_ii_6112_783" x="0" y="0" width="25.7143" height="25.7143" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                      <feOffset dx="5.71428" dy="5.71428"/>
                      <feGaussianBlur stdDeviation="2.85714"/>
                      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0.0771528 0 0 0 0 0.420833 0 0 0 0 0.111521 0 0 0 0.25 0"/>
                      <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6112_783"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                      <feOffset dx="5.71428" dy="5.71428"/>
                      <feGaussianBlur stdDeviation="2.85714"/>
                      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0.00729167 0 0 0 0 0.116667 0 0 0 0 0.0182292 0 0 0 0.25 0"/>
                      <feBlend mode="normal" in2="effect1_innerShadow_6112_783" result="effect2_innerShadow_6112_783"/>
                    </filter>
                    <filter id="filter1_ii_6112_783" x="3" y="3" width="18" height="18" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                      <feOffset dx="4" dy="4"/>
                      <feGaussianBlur stdDeviation="2"/>
                      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0.0771528 0 0 0 0 0.420833 0 0 0 0 0.111521 0 0 0 0.25 0"/>
                      <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6112_783"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                      <feOffset dx="4" dy="4"/>
                      <feGaussianBlur stdDeviation="2"/>
                      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0.00729167 0 0 0 0 0.116667 0 0 0 0 0.0182292 0 0 0 0.25 0"/>
                      <feBlend mode="normal" in2="effect1_innerShadow_6112_783" result="effect2_innerShadow_6112_783"/>
                    </filter>
                    <radialGradient id="paint0_radial_6112_783" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10 10) rotate(90) scale(5.5)">
                      <stop stopColor="#07E11C"/>
                      <stop offset="1" stopColor="#A3E0A9"/>
                    </radialGradient>
                  </defs>
                </svg>
              )}
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <SoftBox lineHeight={1}>
          <SoftBox
            p={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "-1rem!important",
              marginBottom: "0.25rem!important"
            }}
          >
            <SoftBox display="flex" gap="0.5rem">
              <SoftBox>
                <SoftProfile
                  src={kpi.source ? getLogo(kpi.source) : GMB_LOGO_URL}
                  name={kpi.sourceName}
                  size="lg"
                  borderRadius="md"
                  sx={{ background: "#F2F4F7", padding: "0.65rem" }}
                />
              </SoftBox>
              <SoftBox sx={{marginTop: "-0.25rem!important"}}>
                <SoftBox className="flex items-center gap-2">
                  <SoftTypography
                    variant="button"
                    textTransform="capitalize"
                    fontWeight="medium"
                    sx={{ color: "#6C757D", fontSize: "14px", fontWeight: 500 }}
                  >
                    {kpi.title}
                  </SoftTypography>
                  {kpi.isShared && (
                    <Diversity3Icon className="ms-2 mt-[-4px]" />
                  )}
                  {/** KPI is Zapier-integrated */}
                  {isZapier ? (
                    <SoftBox
                      className="max-w-[3rem] flex gap-0 items-center"
                      sx={{
                        cursor: isZapDisconnected ? "pointer" : "auto",
                      }}
                      onClick={
                        isZapDisconnected
                          ? () =>
                              navigate(
                                `/zapier-setup?id=${kpi.integrationMeta.id}`,
                              )
                          : null
                      }
                    >
                      <img
                        src={zapierLogo}
                        alt="Zapier"
                        style={{
                          width: "100%",
                          filter: isZapDisconnected ? "grayscale(80%)" : "none",
                        }}
                      />
                      {isZapDisconnected ? (
                        <FlashOffIcon className="mt-[-2px]" />
                      ) : (
                        <FlashOnIcon className="mt-[-2px] text-[#FF4A00]" />
                      )}
                    </SoftBox>
                  ) : null}
                </SoftBox>
                <SoftTypography
                  variant={pointsBp ? "h2" : "h1"}
                  fontWeight="regular"
                  sx={{ color: "#344054", fontSize: "36px", fontWeight: 400 }}
                >
                  {formatNumber(target, kpi.kind)}&nbsp;
                </SoftTypography>
              </SoftBox>
            </SoftBox>

            <Tooltip title="KPI Settings">
              <SoftButton
                sx={{
                  alignItems: "start",
                  minWidth: "max-content",
                  padding: 0,
                  background: "transparent",
                  boxShadow: "none",
                  height: "max-content",
                  minHeight: "max-content",
                  display: "flex",
                  "& svg": {
                    fontSize: {
                      mini: "1.25rem !important",
                      sm: "1.5rem !important",
                      xl: "1.75rem !important",
                    },
                  },
                }}
                onClick={handleOpen}
              >
                <MoreHorizIcon
                  sx={{
                    color: "rgba(0, 0, 0, 0.4)",
                  }}
                />
              </SoftButton>
            </Tooltip>
            <Popover
              id="hubAppMenu"
              open={openMenu}
              onClose={handleClose}
              anchorEl={openMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{
                style: {
                  boxShadow: "none",
                  padding: "0rem",
                  background: "white",
                  border: "1px solid #00000066",
                },
              }}
            >
              <SoftBox>
                <List>
                  <ListItem sx={{display: kpi.isDraft ? "none" : null}}>
                    <ListItemButton sx={{ gap: "4px" }} onClick={() => navigate(`/gameboards/workspace-kpi/custom-metric-types?kpiid=${kpi._id}`)}>
                      <ListItemIcon sx={{ minWidth: "max-content" }} >
                        <EditIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Update"
                        primaryTypographyProps={{
                          color: "black !important",
                          fontWeight: "medium",
                          variant: "caption",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton sx={{ gap: "4px" }} onClick={handleConfirmationOpen}>
                      <ListItemIcon sx={{ minWidth: "max-content" }} >
                        <DeleteIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Delete"
                        primaryTypographyProps={{
                          color: "black !important",
                          fontWeight: "medium",
                          variant: "caption",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              </SoftBox>
            </Popover>
          </SoftBox>
          {!kpi.isDraft ? (
            <SoftBox
              p={2}
              sx={{
              display: "flex",
              justifyContent: "space-between",
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px',
              marginTop: "-1.5rem!important",
            }}>
              <SoftBox>
                <SoftTypography
                  variant={pointsBp ? "h2" : "h1"}
                  fontWeight="regular"
                  sx={{ color: "#667085", fontSize: "14px", fontWeight: 500 }}
                >
                  {formatNumber(target, kpi.kind)} earns players <span style={{background: '#50DD5F1A', color: '#299E47', borderRadius: '50%'}}>&nbsp;&nbsp;{kpi.pointScale * target}&nbsp;&nbsp;</span> pts
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          ) : null}
        </SoftBox>
      </SoftBox>
      {openPopUp && metricType === KPI_TYPES[2] && (
        <EditDisplayMetric
          kpi={kpi}
          metricType={metricType}
          openEditKpi={openPopUp}
          handleCloseEditKpi={handleClose}
          handleConfirmationOpen={handleConfirmationOpen}
        />
      )}
      {openPopUp && metricType !== KPI_TYPES[2] && (
        <EditKpi
          kpi={kpi}
          openEditKpi={openPopUp}
          handleCloseEditKpi={handleClose}
          handleConfirmationOpen={handleConfirmationOpen}
        />
      )}
      {openConfirmationPopUp && (
        <SoftDialog
          open={openConfirmationPopUp}
          onClose={handleConfirmationClose}
          title={`Delete ${kpi.title} Metric?`}
          type="error"
          icon={<WarningAmberIcon fontSize="large" sx={{ color: "#981B14" }} />}
          description={
            <SoftBox display="flex" flexDirection="column" alignItems="center" mt="25px" mb="35px">
              <SoftTypography
                variant="p"
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#FFF",
                  textAlign: "center",
                  marginBottom: kpi.title === "Yard Sign" ? "20px" : "0",
                }}
              >
                {kpi.title === "Yard Sign" 
                  ? "If you proceed with deleting this metric, it may affect the badge connected to it."
                  : "This action cannot be undone and all associated data will be lost. Proceed with caution."
                }
              </SoftTypography>
              {kpi.title === "Yard Sign" && (
                <>
                <SoftTypography
                variant="p"
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#FFF",
                  textAlign: "center",
                }}
              >
                Type 'confirm' to proceed
              </SoftTypography>
                <SoftInput
                  type="text"
                  placeholder="Type 'confirm' to proceed"
                  value={confirmText}
                  onChange={(e) => setConfirmText(e.target.value)}
                  sx={{
                    width: "100%",
                    borderRadius: "4px",
                    border: "1px solid rgba(233, 236, 239, 0.20)",
                  }}
                />
                </>
              )}
            </SoftBox>
          }
          footer={
            <SoftBox display="flex" gap="10px" justifyContent="center">
              <SoftButton
                size="small"
                variant="contained"
                color="outlined"
                onClick={handleConfirmationClose}
                sx={{
                  textTransform: "none",
                  borderRadius: "4px",
                  border: "1px solid rgba(233, 236, 239, 0.20)",
                  boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                  background: "rgba(255, 255, 255, 0.10)",
                  color: "#CED4DA",
                  "&:hover": {
                    background: "rgba(255, 255, 255, 0.10)",
                  },
                }}
              >
                Cancel
              </SoftButton>
              <SoftButton
                size="small"
                color="white"
                onClick={handleDelete}
                disabled={!isProceedEnabled}
                sx={{
                  width: "min-content",
                  borderRadius: "4px",
                  textTransform: "none",
                  color: "#344054",
                  opacity: !isProceedEnabled ? 0.5 : 1,
                }}
              >
                Proceed
              </SoftButton>
            </SoftBox>
          }
        />
      )}
    </>
  );
};

export default Kpi;
