import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";

import { UseUserDetails } from "../../hooks/auth";
import { UseGroupKpis, UseDraftKpis } from "../../hooks/kpi";
import { UseSelectedWorkspace } from "../../hooks/company";
import { FETCH_GROUP_TYPE } from "../../api/group";
import {
  GET_APP_INTEGRATIONS,
  GET_KPI_INTEGRATIONS_BY_DATASET,
  GET_COMPANY_INTEGRATIONS,
  SUBMIT_REQUEST
} from "../../api/integration";
import {
  GET_ZAPS,
  GET_USER as GET_ZAPIER_USER,
} from "../../api/integrations/zapier";

import {
  updateWorkspaceKpi,
  disableWorkspaceKpi,
} from "../../redux/kpi/actions";

import { Modal, Backdrop, Fade, Box, Grid, Tabs, Tab } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SoftButton from "../SoftButton";
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import { ReactComponent as NewspaperSvg } from "../../img/workspace-newspaper.svg";

import Kpi from "./Kpi";
import CreateCustomKpi from "./metrics/CreateCustomKpi";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from '@mui/icons-material/Close';

import AllocationDialog from "./setup/AllocationDialog";
import SetupDisplayDialog from "./setup/SetupDisplayDialog";
import SetupSelectDialog from "./setup/SetupSelectDialog";

import SoftInput from "../SoftInput";
import SoftSelect from "../SoftSelect";
import { useForm } from "react-hook-form";

import { GMB_LOGO_URL } from "../../constants/branding";
import { useNavigate } from "react-router-dom";
import { fontSize, fontWeight, textAlign } from "@mui/system";

const DIALOG_TYPES = {
  ALLOCATION: "allocation",
  DISPLAY_METRIC: "display_metric",
  SETUP: "setup",
  NONE: null
};

const WorkspaceKpi = () => {
  const [kpis, setKpis] = useState([]);
  const [onKpis, setOnKpis] = useState([]);
  const [allKpis, setAllKpis] = useState([]);
  const groupKpis = UseGroupKpis();
  const workspace = UseSelectedWorkspace();
  const { data: workspaceData } = FETCH_GROUP_TYPE(workspace.typeId);
  const [allApps, setAllApps] = useState([]);
  const [integrationsObj, setIntegrationsObj] = useState(null);
  const [currentAppIntegrations, setCurrentAppIntegrations] = useState({});

  const [newKpi, setNewKpi] = useState(undefined);
  const [currentGroupKpis, setCurrentGroupKpis] = useState(null);
  const [activeDialog, setActiveDialog] = useState(DIALOG_TYPES.NONE);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [connectedApps, setConnecteDApps] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openCustomModal, setOpenCustomModal] = useState(false);

  const formRef = useRef(null);
  const { register, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: {
      image: "",
      name: "",
    },
  });

  const [openModal, setOpenModal] = useState(false);
  const [openMaxMetric, setMaxMetric] = useState(false);
  const [confirmModal, setconfirmModal] = useState(false);

  const handleCloseModal2 = () => {
    setconfirmModal(false);
  };

  const handleCloseMaxMetric = () => {
    setMaxMetric(false);
  }

  const handleMaxMetric = () => {
    setMaxMetric(true);
  };

  const handleImageClick = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const { _id: userId, companyId } = UseUserDetails();
  const submitRequest = async () => {
    const titleValue = getValues("title");
    const integrationValue = getValues("integration");
    const detailsValue = getValues("details");
    if (formRef.current) {
      const body = {
        userId,
        title: titleValue,
        integration: integrationValue,
        details: detailsValue,
      };
      console.log(body);
      await SUBMIT_REQUEST(body);
    }
    setOpenModal(false);
    reset();
    setconfirmModal(true);
  };

  const MAX_WORKSPACE_KPI = 5;

  const addZapierKpis = async (appsData) => {
    try {
      setIsLoading(true);
      const [zapTemplatesResponse, zapUserResponse] = await Promise.all([
        GET_ZAPS(),
        GET_ZAPIER_USER(),
      ]);

      const zapTemplates = zapTemplatesResponse.data;
      const zapUser = zapUserResponse.data;

      zapTemplates.forEach((z) => {
        const idx = appsData.findIndex((app) => {
          return app.name === z.name;
        });
        z.isConnected = !!zapUser;

        const zaps = z.zaps.map((zap) => ({
          ...zap,
          id: z.serviceId,
          // Assign Zapier integration Id for Zapier KPIs that are merged together with other integrated KPIs
          integrationId: z._id,
          // Remove original Game My Biz text from zap since it seems redundant here
          description: zap.description.replace("Game My Biz", ""),
          isZapier: true,
        }));

        if (idx >= 0) {
          appsData[idx].zaps = zaps;
        } else {
          appsData.push({
            ...z,
            zaps,
          });
        }
      });

      setTimeout(() => setIsLoading(false), 500);
      return appsData;
    } catch (err) {
      console.log("zap templates err", err);
      return appsData;
    }
  };

  useEffect(() => {
    if (workspaceData) {
      const getIntegrationsData = async () => {
        const [appsResponse, integResponse, companyIntegrationsResponse] =
          await Promise.all([
            GET_APP_INTEGRATIONS(),
            GET_KPI_INTEGRATIONS_BY_DATASET(),
            GET_COMPANY_INTEGRATIONS(),
          ]);

        let appsData = appsResponse.data;
        const integData = integResponse.data;
        const companyIntegrations = companyIntegrationsResponse.data;

        for (let j = 0; j < appsData.length; j += 1) {
          const { code, serviceId } = appsData[j];
          const idx = companyIntegrations.findIndex((app) => {
            return serviceId ? app.serviceId === serviceId : app.code === code;
          });
          if (idx >= 0) {
            appsData[j].isConnected = true;
            appsData[j].paramKeys = { ...companyIntegrations[idx].parameters };
          } else {
            appsData[j].isConnected = false;
          }
        }
        appsData = await addZapierKpis(appsData);

        appsData.push({
          // Add Manual KPIs
          name: "Game My Biz",
          code: "manual",
          logo: GMB_LOGO_URL,
          description: "Custom-built metrics for your business",
          isConnected: true,
          isManual: true,
          models: workspaceData?.data.map((model) => {
            const { _id, ...rest } = model;
            return {
              kpiModelId: _id,
              ...rest,
            };
          }),
        });

        const appsConnected = appsData.filter(
          (item) => item.isConnected === true,
        );

        // Sort apps: connected ones first then sort alphabetically
        const sortedApps = appsData.sort((app1, app2) => {
          // First, sort by isConnected in descending order (true comes first)
          if (app1.isConnected && !app2.isConnected) {
            return -1;
          }
          if (!app1.isConnected && app2.isConnected) {
            return 1;
          }

          // If isConnected values are equal, sort by isZapier in ascending order (false comes first)
          if (!app1.isZapier && app2.isZapier) {
            return -1;
          }
          if (app1.isZapier && !app2.isZapier) {
            return 1;
          }

          // Next, sort by isManual in ascending order (false comes first)
          if (!app1.isManual && app2.isManual) {
            return -1;
          }
          if (app1.isManual && !app2.isManual) {
            return 1;
          }

          // If isConnected and isZapier values are equal, then sort by name in ascending order
          return app1.name.localeCompare(app2.name);
        });

        setConnecteDApps(appsConnected);
        setAllApps(sortedApps);
        setIntegrationsObj(integData);
        setIsLoading(false);
      };

      getIntegrationsData();
    }
  }, [workspaceData]);

  useEffect(() => {
    if (groupKpis) {
      const appIntegrations = {};
      groupKpis
        .filter((kpi) => kpi.integrationId)
        .forEach((kpi) => {
          (appIntegrations[kpi.integrationId] ??= []).push(kpi.sourceId);
        });

      setCurrentAppIntegrations(appIntegrations);

      // Find the newly-added Integrated GroupKpi
      setCurrentGroupKpis(prev => {
        const groupKpiIds = groupKpis.map(kpi => kpi._id);

        if (Array.isArray(prev)) {
          const newGroupKpiId = groupKpiIds.filter(id => !prev.includes(id))?.[0];
          const newGroupKpi = groupKpis.find(kpi => kpi._id === newGroupKpiId);

          // Full newly-saved "display" GroupKpi data from server
          if (newGroupKpi?._id && groupKpis.length > 0) {
            setNewKpi(prevKpi => {
              if (prevKpi?.isNew) {
                setActiveDialog(DIALOG_TYPES.ALLOCATION);
              }
              return { ...prevKpi, ...newGroupKpi }
             });
          }
        }

        return groupKpiIds;
      });
    }
  }, [groupKpis]);

  useEffect(() => {
    // Assign current workspace KPIs (groupKpis) as "active" KPIs; data is fetched on first load and workspace change
    if (kpis.length) {
      const ons = [];
      const all = [];
      kpis.forEach((kpi) => {
        let active = false;
        const idx = groupKpis?.findIndex((k) => k.kpiModelId === kpi._id);
        if (idx >= 0) {
          active = true;
          ons.push({ ...kpi, ...groupKpis[idx] });
          kpi = { ...kpi, ...groupKpis[idx] };
        }
        all.push({
          ...kpi,
          target: kpi.defaultTarget,
          kpiModelId: kpi._id,
          active,
        });
      });
      setOnKpis(ons);
      setAllKpis(all);
    }
  }, [kpis, groupKpis]);

  const handleSwitch = (kpiId, activating = true) => {
    const idx = allKpis.findIndex((k) => k._id === kpiId);
    if (idx >= 0) {
      if (activating) {
        dispatch(
          updateWorkspaceKpi({ ...allKpis[idx], groupId: workspace._id }),
        );
        setOnKpis([...onKpis, allKpis[idx]]); // Immediately update KPI cards ...
      } else {
        dispatch(disableWorkspaceKpi(kpiId));
        const updatedKpis = [...onKpis]; // Immediately update KPI cards
        updatedKpis.splice(idx, 1); // though this is not too necessary, because the KPI cards will auto-update a bit later
        setOnKpis(updatedKpis); // once redux KPI states updates
      }
    }
  };

  const clearSetupDialogs = (modalDialog) => {
    if (newKpi?.isZapier && newKpi?.integrationMeta) {
      navigate(`/zapier-setup?id=${newKpi?.integrationMeta.id}`);
    } else {
      setActiveDialog(modalDialog ?? DIALOG_TYPES.NONE);
      setNewKpi(null);
    }
  }

  const styleModal = {
    position: 'absolute',
    top: '35%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'linear-gradient(156.05deg, rgba(12, 12, 13, 0.5) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)',
    borderRadius: '10px',
    boxShadow: 24,
    padding: '1.75rem',
    maxWidth: { mini: "90%", xs: "90%", sm: "600px" },
    width: "100%",
    overflow: "visible",
    backdropFilter: 'blur(25px)'
  };

  const [showDrafts, setShowDrafts] = useState(false);

  const draftKpis = UseDraftKpis();
  const [showAll, setShowAll] = useState(0);

  console.log("showAll data:", showAll)

  return (
    <>
      <SoftBox
        sx={{ marginTop: "1rem", marginBottom: "1rem", padding: 4 }}
        borderRadius="lg"
        shadow="md"
        bgColor="white"
      >
        <Box className="flex justify-between mb-6">
          <Box className="flex flex-col px-2">
            <SoftTypography
              variant="h4"
              className="!text-[#051923] !text-[20px]"
            >
              Active Key performance Indicators in Your Workspace

              {/** Instant Metric Allocation Dialog */}
              {(activeDialog === DIALOG_TYPES.ALLOCATION) &&
                <AllocationDialog
                  isOpen
                  handleCancel={() => clearSetupDialogs(DIALOG_TYPES.DISPLAY_METRIC)}
                  handleConfirm={() => setActiveDialog(DIALOG_TYPES.SETUP)}
                />
              }
            </SoftTypography>
            <SoftTypography variant="body2" className="!text-[#6C757D]">
              Your active KPIs, offering real-time insights into your team's
              performance.
            </SoftTypography>
          </Box>
          <Box className="flex flex-col px-2">
            <Box className="flex justify-between mb-6">
              <SoftButton variant="contained" color="primary" sx={{marginRight: '1.5rem'}} onClick={() => {
                navigator.clipboard.writeText(`https://api.gamemybiz.com/webhook?id=${companyId}`);
              }}>
                Copy Webhook URL
              </SoftButton>
              <SoftButton
                onClick={handleImageClick}
                startIcon={
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.40654 11.7398C9.27987 11.7398 9.1532 11.6932 9.0532 11.5932C8.85987 11.3998 8.85987 11.0798 9.0532 10.8865L11.0799 8.85984C11.2732 8.66651 11.5932 8.66651 11.7865 8.85984C11.9799 9.05318 11.9799 9.37318 11.7865 9.56651L9.75989 11.5932C9.66656 11.6865 9.53987 11.7398 9.40654 11.7398Z" fill="#667085"/>
                  <path d="M11.4334 9.71338H4.56006C4.28673 9.71338 4.06006 9.48671 4.06006 9.21338C4.06006 8.94005 4.28673 8.71338 4.56006 8.71338H11.4334C11.7067 8.71338 11.9334 8.94005 11.9334 9.21338C11.9334 9.48671 11.7134 9.71338 11.4334 9.71338Z" fill="#667085"/>
                  <path d="M4.56669 7.28672C4.44003 7.28672 4.31336 7.24006 4.21336 7.14006C4.02003 6.94672 4.02003 6.62672 4.21336 6.43339L6.24001 4.40672C6.43334 4.21339 6.75336 4.21339 6.9467 4.40672C7.14003 4.60005 7.14003 4.92007 6.9467 5.1134L4.92002 7.14006C4.82002 7.24006 4.69336 7.28672 4.56669 7.28672Z" fill="#667085"/>
                  <path d="M11.4334 7.28662H4.56006C4.28673 7.28662 4.06006 7.05995 4.06006 6.78662C4.06006 6.51329 4.28673 6.28662 4.56006 6.28662H11.4334C11.7067 6.28662 11.9334 6.51329 11.9334 6.78662C11.9334 7.05995 11.7134 7.28662 11.4334 7.28662Z" fill="#667085"/>
                  <path d="M8.00016 15.1668C4.04683 15.1668 0.833496 11.9535 0.833496 8.00016C0.833496 4.04683 4.04683 0.833496 8.00016 0.833496C11.9535 0.833496 15.1668 4.04683 15.1668 8.00016C15.1668 11.9535 11.9535 15.1668 8.00016 15.1668ZM8.00016 1.8335C4.60016 1.8335 1.8335 4.60016 1.8335 8.00016C1.8335 11.4002 4.60016 14.1668 8.00016 14.1668C11.4002 14.1668 14.1668 11.4002 14.1668 8.00016C14.1668 4.60016 11.4002 1.8335 8.00016 1.8335Z" fill="#667085"/>
                </svg>
                }
                sx={{
                  background: '#F9FAFB',
                  textTransform: "none",
                  fontSize: "1rem",
                  fontWeight: "400",
                  letterSpacing: "0.095px",
                  paddingX: "1.75rem",
                  marginRight: '1.5rem',
                  color: '#667085'
                }}
              >
                Request Metric
              </SoftButton>
              <Modal
                open={confirmModal}
                onClose={handleCloseModal2}
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={confirmModal}>
                  <SoftBox sx={styleModal}>
                    <SoftBox alignItems="center" sx={{padding: '30px'}}>
                      <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg" style={{margin: "0 auto"}}>
                        <circle cx="35.5" cy="35.5" r="35.2809" fill="url(#paint0_linear_149_11933)" fillOpacity="0.2" stroke="url(#paint1_linear_149_11933)" strokeWidth="0.438272"/>
                        <circle cx="35.5001" cy="35.5001" r="28.7068" fill="url(#paint2_linear_149_11933)" fillOpacity="0.5" stroke="url(#paint3_linear_149_11933)" strokeWidth="0.438272"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M47.7467 25.9868C48.1213 26.3618 48.3316 26.8701 48.3316 27.4001C48.3316 27.9301 48.1213 28.4384 47.7467 28.8134L31.4881 45.0721C31.3004 45.2596 31.0772 45.4079 30.8317 45.5083C30.5861 45.6088 30.323 45.6594 30.0577 45.6571C29.7924 45.6549 29.5302 45.5999 29.2863 45.4953C29.0425 45.3908 28.8219 45.2387 28.6374 45.0481L22.2294 38.4241C21.864 38.0422 21.6645 37.5313 21.6747 37.0028C21.6848 36.4744 21.9038 35.9714 22.2836 35.6039C22.6635 35.2364 23.1734 35.0342 23.7019 35.0415C24.2304 35.0487 24.7345 35.2649 25.1041 35.6428L30.0987 40.8054L44.9201 25.9868C45.2951 25.6122 45.8034 25.4019 46.3334 25.4019C46.8634 25.4019 47.3717 25.6122 47.7467 25.9868Z" fill="#299E47"/>
                        <defs>
                        <linearGradient id="paint0_linear_149_11933" x1="35.5" y1="3.28704" x2="35.7772" y2="104.192" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#299E47" stopOpacity="0"/>
                        <stop offset="0.300199" stopColor="#299E47" stopOpacity="0.06"/>
                        <stop offset="1" stopColor="#299E47"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_149_11933" x1="35.5" y1="0" x2="35.5" y2="87.2161" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#98A2B3" stopOpacity="0.03"/>
                        <stop offset="1" stopColor="#41464D" stopOpacity="0.16"/>
                        </linearGradient>
                        <linearGradient id="paint2_linear_149_11933" x1="35.5001" y1="6.57422" x2="36.6752" y2="98.3835" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#299E47" stopOpacity="0.5"/>
                        <stop offset="0.356773" stopColor="#299E47" stopOpacity="0.2"/>
                        <stop offset="1" stopColor="#299E47" stopOpacity="0.7"/>
                        </linearGradient>
                        <linearGradient id="paint3_linear_149_11933" x1="35.5001" y1="6.57422" x2="35.5001" y2="64.4261" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#475467" stopOpacity="0.44"/>
                        <stop offset="1" stopColor="#8DA7CD" stopOpacity="0.06"/>
                        </linearGradient>
                        </defs>
                      </svg>
                      <SoftTypography sx={{textFillColor: "#ffffff", fontSize: '28px', textAlign: 'center', marginTop: '1rem'}} variant="h6" component="h2">
                        Metric Request Received
                      </SoftTypography>
                      <SoftTypography sx={{textFillColor: "#ffffff", textAlign: 'center', fontSize: '16px', fontWeight: '400', marginTop: '1.5rem'}} variant="p" component="p">
                        Your request will be reviewed promptly, and we'll have the metric ready for you in no time!
                      </SoftTypography>
                      <SoftBox alignItems="center" sx={{textAlign: 'center'}}>
                        <SoftButton sx={{width: '40%', marginTop: '5rem'}} onClick={handleCloseModal2}>
                          Okay 
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Fade>
              </Modal>
              <Modal
                open={openModal}
                onClose={handleCloseModal}
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={openModal}>
                  <SoftBox sx={styleModal}>
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <SoftTypography sx={{textFillColor: "#ffffff"}} variant="h6" component="h2">
                        Request Metric Creation
                      </SoftTypography>
                      <SoftButton
                        variant="outlined"
                        color="white"
                        onClick={handleCloseModal}
                        sx={{
                          "& svg": { fontSize: "1.25rem !important" },
                          borderRadius: "50%",
                          minWidth: "max-content",
                          minHeight: "max-content",
                          padding: "0.25rem",
                        }}
                      >
                        <CloseIcon />
                      </SoftButton>
                    </SoftBox>
                    <hr style={{marginTop: '1rem', marginBottom: '1rem'}}/>
                    <SoftTypography sx={{textFillColor: "#ffffff", textAlign: 'center', fontSize: '14px', fontWeight: '400'}} variant="p" component="p">
                      Not sure how to set up the right metric for your team? We're here to help! 
                    </SoftTypography>
                    <SoftTypography sx={{textFillColor: "#ffffff", textAlign: 'center', fontSize: '14px', fontWeight: '400'}} variant="p" component="p">
                      Simply fill out the details below, and our expert team will create the perfect metric
                    </SoftTypography>
                    <SoftBox sx={{marginTop: '2rem'}}>
                      <form ref={formRef}>
                        <SoftTypography sx={{textFillColor: "#ffffff", fontSize: '14.5px', fontWeight: '400'}} variant="p" component="p">
                          Name of desired metric
                        </SoftTypography>
                        <SoftInput
                          {...register("title")}
                          name="title"
                          type="text"
                          placeholder="Title of the metric"
                          sx={{
                            border: "1px solid rgba(233, 236, 239, 0.10)",
                            backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                            color: "#ffffffb3 !important",
                            "& .MuiInputBase-input::placeholder": {
                              color: "white !important",
                              opacity: "0.7",
                            },
                            marginBottom: '1rem'
                          }}
                          size="large"
                        />
                        <SoftTypography sx={{textFillColor: "#ffffff", fontSize: '14.5px', fontWeight: '400', marginTop: '1rem'}} variant="p" component="p">
                          Any integrations in mind?
                        </SoftTypography>
                        <SoftInput
                          {...register("integration")}
                          name="integration"
                          type="text"
                          placeholder="Please specify"
                          sx={{
                            border: "1px solid rgba(233, 236, 239, 0.10)",
                            backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                            color: "#ffffffb3 !important",
                            "& .MuiInputBase-input::placeholder": {
                              color: "white !important",
                              opacity: "0.7",
                            },
                            marginBottom: '1rem'
                          }}
                          size="large"
                        />
                        <SoftTypography sx={{textFillColor: "#ffffff", fontSize: '14.5px', fontWeight: '400'}} variant="p" component="p">
                          Details 
                        </SoftTypography>
                        <SoftInput
                          {...register("details")}
                          name="details"
                          type="textarea"
                          placeholder="Any specific requirements or desired outcome"
                          sx={{
                            border: "1px solid rgba(233, 236, 239, 0.10)",
                            backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                            color: "#ffffffb3 !important",
                            "& .MuiInputBase-input::placeholder": {
                              color: "white !important",
                              opacity: "0.7",
                            },
                            marginBottom: '1rem'
                          }}
                          size="large"
                        />
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" sx={{textAlign: 'center'}}>
                          <SoftButton sx={{width: '40%', marginTop: '1.5rem', background: '#F9FAFB!important', color: '#667085!important'}} onClick={handleCloseModal}>
                            Cancel
                          </SoftButton>
                          <SoftButton sx={{width: '40%', marginTop: '1.5rem'}} onClick={submitRequest}>
                            Submit
                          </SoftButton>
                        </SoftBox>
                      </form>
                    </SoftBox>
                  </SoftBox>
                </Fade>
              </Modal>
              <Modal
                open={openMaxMetric}
                onClose={handleCloseModal}
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={openMaxMetric}>
                  <SoftBox sx={styleModal}>
                    <SoftBox display="flex" justifyContent="center" alignItems="center">
                      <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="35.5" cy="35.5" r="35.2809" fill="url(#paint0_linear_175_5395)" fillOpacity="0.2" stroke="url(#paint1_linear_175_5395)" strokeWidth="0.438272"/>
                        <circle cx="35.5001" cy="35.5001" r="28.7068" fill="url(#paint2_linear_175_5395)" fillOpacity="0.5" stroke="url(#paint3_linear_175_5395)" strokeWidth="0.438272"/>
                        <path d="M36.0001 28.333C36.2653 28.333 36.5197 28.4384 36.7072 28.6259C36.8947 28.8134 37.0001 29.0678 37.0001 29.333V35.333C37.0001 35.5982 36.8947 35.8526 36.7072 36.0401C36.5197 36.2276 36.2653 36.333 36.0001 36.333C35.7349 36.333 35.4805 36.2276 35.293 36.0401C35.1054 35.8526 35.0001 35.5982 35.0001 35.333V29.333C35.0001 29.0678 35.1054 28.8134 35.293 28.6259C35.4805 28.4384 35.7349 28.333 36.0001 28.333ZM36.0001 41.6663C36.3537 41.6663 36.6928 41.5259 36.9429 41.2758C37.1929 41.0258 37.3334 40.6866 37.3334 40.333C37.3334 39.9794 37.1929 39.6402 36.9429 39.3902C36.6928 39.1401 36.3537 38.9997 36.0001 38.9997C35.6465 38.9997 35.3073 39.1401 35.0573 39.3902C34.8072 39.6402 34.6667 39.9794 34.6667 40.333C34.6667 40.6866 34.8072 41.0258 35.0573 41.2758C35.3073 41.5259 35.6465 41.6663 36.0001 41.6663Z" fill="#981B14"/>
                        <path d="M29.7707 20.9603C29.8635 20.8674 29.9736 20.7936 30.0949 20.7433C30.2161 20.693 30.3461 20.667 30.4774 20.667H41.5227C41.7881 20.667 42.0414 20.7723 42.2294 20.9603L50.0401 28.771C50.2281 28.9577 50.3334 29.211 50.3334 29.4777V40.523C50.3334 40.6543 50.3074 40.7843 50.2571 40.9055C50.2068 41.0268 50.133 41.1369 50.0401 41.2297L42.2294 49.0403C42.1367 49.1333 42.0265 49.207 41.9053 49.2574C41.784 49.3077 41.654 49.3336 41.5227 49.3337H30.4774C30.3461 49.3336 30.2161 49.3077 30.0949 49.2574C29.9736 49.207 29.8635 49.1333 29.7707 49.0403L21.9601 41.2297C21.8671 41.1369 21.7934 41.0268 21.7431 40.9055C21.6927 40.7843 21.6668 40.6543 21.6667 40.523V29.4777C21.6667 29.2123 21.7721 28.959 21.9601 28.771L29.7707 20.9603ZM30.8907 22.667L23.6667 29.8923V40.1083L30.8921 47.3337H41.1081L48.3334 40.1097V29.891L41.1094 22.667H30.8907Z" fill="#981B14"/>
                        <defs>
                        <linearGradient id="paint0_linear_175_5395" x1="35.5" y1="3.28704" x2="35.7772" y2="104.192" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#9E2929" stopOpacity="0"/>
                        <stop offset="0.300199" stopColor="#9E2929" stopOpacity="0.06"/>
                        <stop offset="1" stopColor="#9E2929"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_175_5395" x1="35.5" y1="0" x2="35.5" y2="87.2161" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#98A2B3" stopOpacity="0.03"/>
                        <stop offset="1" stopColor="#41464D" stopOpacity="0.16"/>
                        </linearGradient>
                        <linearGradient id="paint2_linear_175_5395" x1="35.5001" y1="6.57422" x2="36.6752" y2="98.3835" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#9E2929" stopOpacity="0"/>
                        <stop offset="0.356773" stopColor="#9E2929" stopOpacity="0.2"/>
                        <stop offset="1" stopColor="#9E2929" stopOpacity="0.7"/>
                        </linearGradient>
                        <linearGradient id="paint3_linear_175_5395" x1="35.5001" y1="6.57422" x2="35.5001" y2="64.4261" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#475467" stopOpacity="0.44"/>
                        <stop offset="1" stopColor="#8DA7CD" stopOpacity="0.06"/>
                        </linearGradient>
                        </defs>
                      </svg>
                    </SoftBox>
                    <SoftTypography sx={{textFillColor: "#ffffff", textAlign: 'center', fontSize: '28px', fontWeight: '500', marginTop: '1rem', marginBottom: '1rem'}} variant="p" component="p">
                      Maximum Metrics Reached
                    </SoftTypography>
                    <SoftTypography sx={{textFillColor: "#ffffff", textAlign: 'center', fontSize: '18px', fontWeight: '400', marginBottom: '3rem'}} variant="p" component="p">
                      You have already created the maximum number of<br/> metrics allowed in this workspace. To create more,<br/> you'll need to delete an existing metric. 
                    </SoftTypography>
                    <SoftBox display="flex" justifyContent="center" alignItems="center" sx={{textAlign: 'center'}}>
                      <SoftButton sx={{width: '40%', marginTop: '1.5rem', background: '#F9FAFB!important', color: '#667085!important'}} onClick={handleCloseMaxMetric}>
                        Okay
                      </SoftButton>
                    </SoftBox>
                  </SoftBox>
                </Fade>
              </Modal>
              <SoftButton
                color="primary"
                onClick={() => {
                  if (groupKpis && groupKpis.filter(kpi => !kpi.isDraft).length >= MAX_WORKSPACE_KPI) {
                    handleMaxMetric();
                  } else {
                    navigate("/gameboards/workspace-kpi/custom-metric-types");
                  }
                }}
                startIcon={<AddIcon sx={{ width: "1.5rem", height: "1.5rem" }} />}
                sx={{
                  textTransform: "none",
                  fontSize: "1rem",
                  fontWeight: "400",
                  letterSpacing: "0.095px",
                  paddingX: "1.75rem",
                }}
              >
                Create Custom Metric
              </SoftButton>
            </Box>
          </Box>
          <CreateCustomKpi
            openCustomKpi={openCustomModal}
            handleCloseCustomKpi={() => setOpenCustomModal(false)}
          />
        </Box>
        <Box>
          <Box
            sx={{
              marginTop: { mini: "1rem", lg: "0rem" },
              marginBottom: "2rem",
              width: "100%",
            }}
          >
            <Grid container spacing={3} mb="2rem">
              <Grid item mini={4} >
                <SoftBox>
                  <Tabs
                    value={showAll}
                    onChange={(e, value) => setShowAll(value)}
                    sx={{
                      backgroundColor: "#F9FAFB",
                      borderRadius: "8px",
                      padding: "10px",
                      width: "100%",
                      "& .MuiTabs-indicator": {
                        borderRadius: "6px",
                        background: "#FFFFFF",
                        boxShadow: "0px 1.2px 3.6px rgba(16, 24, 40, 0.1), 0px 1.2px 2.4px rgba(16, 24, 40, 0.06)",
                      },
                      "& .MuiTab-root": {
                        padding: "10px",
                        flex: 1,
                        "&.Mui-selected": {
                          color: "#667085",
                        },
                      },
                    }}
                >
                  <Tab
                    label={`Game Metrics (${groupKpis?.length})`}
                    icon={
                      <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.11501 2.12598C9.34241 2.12598 9.56981 2.20868 9.74898 2.36717C10.3623 2.91156 11.3684 3.96587 12.0575 5.43366C12.2022 5.74376 12.3331 6.06764 12.4434 6.41219C12.533 6.70162 12.6088 6.99103 12.6708 7.28735C12.9947 8.87918 12.6363 10.5261 12.1333 12.0422C11.9403 12.6348 11.7887 13.317 11.4373 13.8407C11.4166 13.8683 11.4028 13.8958 11.389 13.9165C11.203 14.1646 10.9136 14.3093 10.6035 14.3093C10.6035 14.3093 9.85924 14.3093 9.11501 14.3093C8.37078 14.3093 7.62653 14.3093 7.62653 14.3093C7.31644 14.3093 7.02703 14.1646 6.84097 13.9165C6.82719 13.889 6.8134 13.8683 6.79272 13.8407C6.44128 13.317 6.28967 12.6417 6.09672 12.0422C5.60057 10.5261 5.23535 8.87918 5.55922 7.28735C5.62124 6.99103 5.69706 6.70162 5.78664 6.41219C5.8969 6.06764 6.02783 5.74376 6.17254 5.43366C6.86164 3.96587 7.86773 2.91845 8.48103 2.36717C8.6602 2.20868 8.8876 2.12598 9.11501 2.12598Z" stroke="#E9ECEF" strokeWidth="1.03365" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M11.4375 13.8397L12.671 14.7493C12.8571 14.8872 13.0845 14.9147 13.2981 14.8389C13.3877 14.7976 13.4635 14.7493 13.5255 14.6873C13.6151 14.5977 13.6771 14.4875 13.7046 14.3565L14.1457 12.1721C14.2077 11.8758 14.125 11.5864 13.9389 11.3589L12.7606 9.98763C12.7675 9.97385 12.7675 9.96007 12.7675 9.94629" stroke="#E9ECEF" strokeWidth="1.03365" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M5.46406 9.98047L4.28568 11.3518C4.09962 11.5792 4.01694 11.8755 4.07896 12.1649L4.51997 14.3494C4.56131 14.5699 4.71983 14.7491 4.92656 14.8318C5.14018 14.9076 5.36756 14.88 5.55362 14.7422L6.78712 13.8326" stroke="#E9ECEF" strokeWidth="1.03365" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M9.11491 8.35581C9.86844 8.35581 10.4793 7.74492 10.4793 6.99138C10.4793 6.23783 9.86844 5.62695 9.11491 5.62695C8.36138 5.62695 7.75049 6.23783 7.75049 6.99138C7.75049 7.74492 8.36138 8.35581 9.11491 8.35581Z" stroke="#E9ECEF" strokeWidth="1.03365" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M6.38623 11.7666H11.8439" stroke="#E9ECEF" strokeWidth="1.03365" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    }
                    sx={{
                      fontSize: "0.75rem",
                      ".css-3k008v-MuiButtonBase-root-MuiTab-root": {
                        borderRadius: "4px !important", 
                        background: "inherit",
                        padding: "10px 15px",
                      },
                    }}
                  />
                  <Tab
                    label={`Data Metrics (${draftKpis?.length})`}
                    icon={
                      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.0918 13.8337V12.5003H12.0918C12.2807 12.5003 12.4391 12.4363 12.5671 12.3083C12.6951 12.1803 12.7589 12.0221 12.7585 11.8337V10.5003C12.7585 10.0781 12.8807 9.69477 13.1251 9.35033C13.3696 9.00588 13.6918 8.76144 14.0918 8.61699V8.38366C13.6918 8.23921 13.3696 7.99477 13.1251 7.65033C12.8807 7.30588 12.7585 6.92255 12.7585 6.50033V5.16699C12.7585 4.9781 12.6945 4.81988 12.5665 4.69233C12.4385 4.56477 12.2802 4.50077 12.0918 4.50033H10.0918V3.16699H12.0918C12.6474 3.16699 13.1196 3.36144 13.5085 3.75033C13.8974 4.13921 14.0918 4.61144 14.0918 5.16699V6.50033C14.0918 6.68921 14.1558 6.84766 14.2838 6.97566C14.4118 7.10366 14.57 7.16744 14.7585 7.16699H15.4251V9.83366H14.7585C14.5696 9.83366 14.4114 9.89766 14.2838 10.0257C14.1562 10.1537 14.0922 10.3119 14.0918 10.5003V11.8337C14.0918 12.3892 13.8974 12.8614 13.5085 13.2503C13.1196 13.6392 12.6474 13.8337 12.0918 13.8337H10.0918ZM5.42513 13.8337C4.86957 13.8337 4.39735 13.6392 4.00846 13.2503C3.61957 12.8614 3.42513 12.3892 3.42513 11.8337V10.5003C3.42513 10.3114 3.36113 10.1532 3.23313 10.0257C3.10513 9.8981 2.94691 9.8341 2.75846 9.83366H2.0918V7.16699H2.75846C2.94735 7.16699 3.1058 7.10299 3.2338 6.97499C3.3618 6.84699 3.42557 6.68877 3.42513 6.50033V5.16699C3.42513 4.61144 3.61957 4.13921 4.00846 3.75033C4.39735 3.36144 4.86957 3.16699 5.42513 3.16699H7.42513V4.50033H5.42513C5.23624 4.50033 5.07802 4.56433 4.95046 4.69233C4.82291 4.82033 4.75891 4.97855 4.75846 5.16699V6.50033C4.75846 6.92255 4.63624 7.30588 4.3918 7.65033C4.14735 7.99477 3.82513 8.23921 3.42513 8.38366V8.61699C3.82513 8.76144 4.14735 9.00588 4.3918 9.35033C4.63624 9.69477 4.75846 10.0781 4.75846 10.5003V11.8337C4.75846 12.0225 4.82246 12.181 4.95046 12.309C5.07846 12.437 5.23669 12.5008 5.42513 12.5003H7.42513V13.8337H5.42513Z" fill="currentColor"/>
                      </svg>
                    }
                    sx={{
                      fontSize: "0.75rem",
                      ".css-3k008v-MuiButtonBase-root-MuiTab-root": {
                        borderRadius: "4px !important",
                        background: "inherit", 
                        padding: "10px 15px",
                      },
                    }}
                  />
                </Tabs>
                </SoftBox>
              </Grid>
            </Grid>
            {showAll === 0 && groupKpis && groupKpis?.length > 0 ? (
              <Grid container spacing={3}>
                {groupKpis?.filter(kpi => !kpi.isDraft).map((kpi) => (
                  <Grid
                    item
                    mini={12}
                    md={4}
                    xxl={12 / groupKpis?.filter(item => !item.isDraft)?.length}
                    key={kpi._id}
                  >
                    <Kpi kpi={kpi} target={kpi.target} allApps={allApps} />
                  </Grid>
                ))}
              </Grid>
            ) : null}
            {showAll === 1 && draftKpis && draftKpis?.length > 0 ? ( 
              <Grid container spacing={3}>
                {draftKpis?.map((kpi) => {
                  const draftCount = draftKpis?.length || 1;
                  return (
                    <Grid
                      item
                      mini={12}
                      md={4}
                      xxl={12 / draftCount}
                      key={kpi._id}
                    >
                      <Kpi kpi={kpi} target={kpi.target} allApps={allApps} />
                    </Grid>
                  );
                })}
              </Grid>
            ) : null}
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              marginTop: "3rem",
              marginBottom: "2rem",
              width: "100%",
            }}
          >
            <Grid container spacing={3}>
              <Grid item mb={3}>
                <SoftBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <SoftTypography
                    variant="h4"
                    className="!text-[#051923] !text-[18px] cursor-pointer"
                    onClick={() => setShowDrafts(!showDrafts)} 
                  >
                    {showDrafts ? "Hide" : "Show"} Data Metrics
                  </SoftTypography>
                  {showDrafts ? (
                    <KeyboardArrowUpIcon style={{ color: "#051923", width: "20px", height: "20px" }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ color: "#051923", width: "20px", height: "20px" }} />
                  )}
                </SoftBox>
              </Grid>
            </Grid>
            {showDrafts && draftKpis && draftKpis?.length > 0 ? ( 
              <Grid container spacing={3}>
                <Grid
                  item
                  mini={12}
                  md={4}
                  xxl={12}
                >
                  <SoftTypography
                    variant="h5"
                    color="black"
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    Save Metrics Here
                  </SoftTypography>
                </Grid>
              </Grid>
            ) : null}
          </Box>
        </Box>
      </SoftBox>

      {(activeDialog === DIALOG_TYPES.DISPLAY_METRIC) &&
        <SetupDisplayDialog
          open
          handleReset={clearSetupDialogs}
        />
      }

      {(activeDialog === DIALOG_TYPES.SETUP) &&
        <SetupSelectDialog
          open
          metric={newKpi}
          handleReset={clearSetupDialogs}
        />
      }
    </>
  );
};

export default WorkspaceKpi;
