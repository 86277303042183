import React, { useState, useEffect, useMemo } from "react";
import Grid from "@mui/material/Grid";
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import SoftInput from "../SoftInput";
import SoftSelect from "../SoftSelect";
import SoftButton from "../SoftButton";
import AppCard from "./apps/AppCard";
import MetricCard from "./metrics/MetricCard";
import LoadingScreen from "../LoadingScreen";

import startCase from "lodash/startCase";
import debounce from "lodash/debounce";
import { ReactComponent as SearchSvg } from "../../img/search.svg";
import { MAX_WORKSPACE_KPIS } from "../../constants/kpi";
import "./configs/styles.css";

const SEARCH_ALL = "all";
const SEARCH_APPS = "apps";
const SEARCH_METRICS = "metrics";
const SEARCH_OPTIONS = [SEARCH_ALL, SEARCH_APPS, SEARCH_METRICS].map(
  (item) => ({ value: item, label: startCase(item) }),
);

const ConnectedApplications = ({
  apps,
  connectedApps,
  groupKpis,
  integrationsObj,
  currentAppIntegrations,
  functions,
  isLoading,
}) => {
  const [searchMode, setSearchMode] = useState(SEARCH_OPTIONS[0]);
  const [allApps, setAllApps] = useState([]);
  const [allMetrics, setAllMetrics] = useState([]);
  const [filteredApps, setFilteredApps] = useState([]);
  const [filteredMetrics, setFilteredMetrics] = useState([]);
  const [filterKey, setFilterKey] = useState("");

  // Detect activated metrics key existence in an Object instead of searching in an Array
  // (array loop optimization for very long metrics list)
  const activeMetricsSimpleMap = useMemo(() => {
    return groupKpis?.reduce((obj, item) => {
      const metricComboKey = `${item?.source ?? item?.kpiModelId ?? ""}|${item?.sourceId ?? ""}`;
      return { ...obj, [metricComboKey]: item._id }
    }, {});
  }, [groupKpis]);

  useEffect(() => {
    if (apps && apps.length && integrationsObj) {
      const allAppIntegrations = [];
      const appsList = [...apps].map((app) => {
        const officialIntegrations = app.dataSets
          ? app.dataSets.flatMap(
              (d) =>
                (integrationsObj[d] ?? []).map(
                  ({ sourceId, type, description }) => ({
                    type,
                    description,
                    app: app.name,
                    logo: app.logo,
                    code: app.code,
                    sourceId,
                    integrationId: app._id,
                    isConnected: app.isConnected,
                  }),
                ) ?? [],
            )
          : [];

        // Merge GMB data cubes (template manual KPIs) and Zapier zaps with official integrations
        const allIntegrations = [
          ...officialIntegrations,
          ...(app.models?.map((m) => ({
            ...m,
            app: app.name,
            logo: app.logo,
            code: app.code,
            integrationId: null,
            isManual: true,
            isConnected: true,
          })) ?? []),
          ...(app.zaps?.map((z) => ({
            ...z,
            app: app.name,
            logo: app.logo,
            code: app.code,
            integrationId: app._id,
            isConnected: app.isConnected,
          })) ?? []),
        ];

        allAppIntegrations.push(...allIntegrations);
        return { ...app, metrics: allIntegrations };
      });

      // Sort metrics: connected ones first then sort alphabetically
      const sortedMetrics = allAppIntegrations.sort((metric1, metric2) => {
        // First, sort by isConnected in descending order (true comes first)
        if (metric1.isConnected && !metric2.isConnected) {
          return -1;
        }
        if (!metric1.isConnected && metric2.isConnected) {
          return 1;
        }

        // If isConnected values are equal, sort by isZapier in ascending order (false comes first)
        if (!metric1.isZapier && metric2.isZapier) {
          return -1;
        }
        if (metric1.isZapier && !metric2.isZapier) {
          return 1;
        }

        // Next, sort by isManual in ascending order (false comes first)
        if (!metric1.isManual && metric2.isManual) {
          return -1;
        }
        if (metric1.isManual && !metric2.isManual) {
          return 1;
        }

        // If isConnected and isZapier values are equal, then sort by type in ascending order
        return metric1.type.localeCompare(metric2.type);
      });

      setAllApps(appsList);
      setFilteredApps(appsList);
      setAllMetrics(sortedMetrics); // set master list of all app's KPI integrations
      setFilteredMetrics(sortedMetrics);
    }
  }, [apps, connectedApps, integrationsObj]);

  const handleSearch = debounce(
    (e) => {
      const filter = e.target.value.trim().toLowerCase();
      setFilterKey(filter);

      if (allApps.length && allMetrics.length) {
        const matchingApps = [...allApps].filter((item) =>
          item.name.toLowerCase().includes(filter),
        );
        setFilteredApps(matchingApps);

        const matchingMetrics = [...allMetrics].filter(
          (item) =>
            item.app.toLowerCase().includes(filter) ||
            item.type.toLowerCase().includes(filter),
        );
        setFilteredMetrics(matchingMetrics);
      }
    },
    [500],
  );

  return (
    <SoftBox
      sx={{ marginTop: "1rem", marginBottom: "1rem", padding: 4 }}
      borderRadius="lg"
      shadow="md"
      bgColor="white"
      className="min-h-[50vh]"
    >
      <SoftBox
        justifyContent="space-between"
        sx={{ display: { mini: "block", md: "flex" } }}
      >
        <SoftBox>
          <SoftTypography variant="h4" className="!text-[#051923] !text-[20px]">
            Get Instant Metric
          </SoftTypography>
          <SoftTypography variant="body2" className="!text-[#6C757D]">
          Seamlessly integrate and monitor key metrics from platforms applications<br/> directly within your workspace
          </SoftTypography>
        </SoftBox>

        <SoftBox position="relative">
          <SoftBox
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              gap: 4,
            }}
          >
            <SoftInput
              variant="outlined"
              placeholder={`Search for ${searchMode.label}`}
              sx={{
                background: "none !important",
                width: "300px !important",
              }}
              onChange={handleSearch}
              icon={{
                component: <SearchSvg style={{ color: "#CED4DA" }} />,
                direction: "left",
              }}
            />
            <SoftSelect
              color="primary"
              options={SEARCH_OPTIONS}
              value={searchMode}
              onChange={(option) => setSearchMode(option)}
              styles={{
                control: {
                  width: "100px",
                },
              }}
            />
          </SoftBox>
        </SoftBox>
      </SoftBox>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          {filterKey &&
            filteredApps.length === 0 &&
            filteredMetrics.length === 0 && (
              <div className="w-full flex flex-col justify-center items-center mt-8">
                <SoftTypography variant="h5">
                  Didn't find what you were looking for?
                </SoftTypography>
              </div>
            )}
          {searchMode.value !== SEARCH_METRICS && (
            <SoftBox sx={{ marginBottom: "2rem", marginTop: "2rem" }}>
              <Grid container spacing={2}>
                {filteredApps.map((app) => (
                  <Grid
                    item
                    mini={12}
                    lg={4}
                    xl={3}
                    key={`${app.name}-${app._id}`}
                  >
                    <AppCard
                      app={app}
                      keyword={filterKey}
                      functions={functions}
                      workspaceKpiCount={groupKpis?.filter(kpi => !kpi.isDraft).length ?? 0}
                      groupKpis={groupKpis}
                    />
                  </Grid>
                ))}
              </Grid>
            </SoftBox>
          )}
          {searchMode.value !== SEARCH_APPS && (
            <SoftBox sx={{ marginBottom: "2rem", marginTop: "2rem" }}>
              <Grid container spacing={2}>
                {filteredMetrics.map((metric, index) => {
                  // Use kpiModelId if Game My Biz Data Cube metric
                  const code = (metric?.app === "Game My Biz")
                    ? metric.kpiModelId
                    : metric?.code;

                  return <Grid
                    item
                    mini={12}
                    lg={4}
                    xl={3}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${metric.type}-${index}`}
                  >
                    <MetricCard
                      metric={metric}
                      keyword={filterKey}
                      disabled={
                        groupKpis?.length >= MAX_WORKSPACE_KPIS ||
                        !metric.isConnected ||
                        activeMetricsSimpleMap[`${code ?? ""}|${metric?.sourceId ?? ""}`]
                      }
                      onAddMetric={(kpi) => functions.handleLocalKpi(kpi)}
                    />
                  </Grid>
                })}
              </Grid>
            </SoftBox>
          )}
        </>
      )}
    </SoftBox>
  );
};

export default ConnectedApplications;
