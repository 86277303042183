export const anchorPointTypes = [
  { value: "percentage", label: "%" },
  { value: "dollar", label: "$" },
  { value: "item", label: "#" },
];
export const dataSourceOneOptions = [
  { value: "facebook-ads-revenue", label: "Facebook Ads Revenue" },
  { value: "google-revenue", label: "Google Revenue" },
];
export const calculationOptions = [
  { value: "add", label: "Add +" },
  { value: "subtract", label: "Subtract -" },
  { value: "multiply", label: "Multiply x" },
  { value: "divide", label: "Divide/Average ÷" },
];
export const dataSourceTwoOptions = [
  { value: "facebook-ads-revenue", label: "Facebook Ads Revenue" },
  { value: "google-revenue", label: "Google Revenue" },
];
