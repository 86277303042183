/* eslint camelcase: 0 */
import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ListSelectionDialog from "../../components/ListSelectionDialog/ListSelectionDialog";
import { GET_BUSINESS_LOCATIONS } from "../../api/google";
import { configureIntegration } from "../../redux/kpi/actions";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

import { GOOGLE_BUSINESS } from "../../constants/integrations";

const GoogleBusinessWindow = () => {
  const [locations, setLocations] = useState([]);
  const [selections, setSelections] = useState([]);
  const [isLoading, setIsLaoding] = useState(true);
  const [searchParams] = useSearchParams();
  const access_token = searchParams.get("access_token");
  const refresh_token = searchParams.get("refresh_token");
  const token_type = searchParams.get("token_type");
  const error = searchParams.get("error");
  const integrationId = searchParams.get("integrationId");
  const mode = searchParams.get("mode");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closeWindow = () => {
    window.open("", "_self").close();
  };

  const handleSelect = (id) => {
    const location = locations[id];
    if (mode === "company" || mode === "arena") {
      dispatch(
        configureIntegration({
          integrationId,
          code: GOOGLE_BUSINESS,
          parameters: {
            refresh_token,
            token_type,
            account_id: location.accountId,
            location_id: location.locationId,
            business_name: location.title,
            address: location.formattedAddress,
          },
        }),
      );
      if (mode === "company") {
        navigate("/gameboards/workspace-kpi/custom-metric-types");
      } else {
        setIsLaoding(true);
        setTimeout(() => {
          closeWindow();
        }, 3000);
      }
    } else {
      localStorage.setItem("location", JSON.stringify(location));
      closeWindow();
    }
  };

  useEffect(() => {
    if (access_token && refresh_token) {
      const googleItem = JSON.parse(localStorage.getItem("google")) || {};
      googleItem.refresh_token = refresh_token;
      googleItem.token_type = token_type;
      // Store Google credentials temporarily in local storage
      // Google auth opens in new window, so we'll use local storage to pass it to main app window
      localStorage.setItem("google", JSON.stringify(googleItem));

      GET_BUSINESS_LOCATIONS({ access_token, token_type })
        .then(({ data }) => {
          const locs = {};
          const options = data.map((loc) => {
            const address = loc.storefrontAddress;
            const formattedAddress = address
              ? `${address.addressLines.join(" ")} ${address.locality} ${
                  address.administrativeArea
                },${address.postalCode}`
              : "";
            locs[loc.locationId] = {
              ...loc,
              formattedAddress,
            };
            return {
              id: loc.locationId,
              header1: loc.title,
              subheader: loc.locationId,
              text: formattedAddress,
            };
          });
          setLocations(locs);
          setSelections(options);
          setIsLaoding(false);
        })
        .catch(() => {
          setIsLaoding(false);
        });
    } else {
      setIsLaoding(false);
    }
  }, [access_token, refresh_token, token_type]);

  useEffect(() => {
    if (error === "access_denied") {
      closeWindow();
    }
  }, [error]);

  return (
    <Box className="flex items-center justify-center h-[100vh] w-[100vw]">
      {isLoading && (
        <Box className="flex items-center">
          <CircularProgress size="10rem" />
        </Box>
      )}
      {!isLoading &&
        (selections.length ? (
          <ListSelectionDialog
            title="Select Business Location"
            items={selections}
            onSelect={({ id }) => {
              handleSelect(id);
            }}
            autoOpen={true}
            yes="Select"
          />
        ) : (
          <Box className="flex items-center">
            <Stack className="flex items-center" spacing={2}>
              <p>No business locations found.</p>
              <Button variant="contained" onClick={closeWindow}>
                Exit
              </Button>
            </Stack>
          </Box>
        ))}
    </Box>
  );
};

export default GoogleBusinessWindow;
